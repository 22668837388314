import { useI18n } from 'vue-i18n';
import type { Header } from '~/components/ui/data/DataTable.vue';

export const useTariffTable = () => {
  // Composables
  const { t } = useI18n();

  const headers = ref<Header[]>([
    { key: 'name', label: t('contract.tariff_name') },
    { key: 'price', label: t('global.price') },
    { key: 'month', label: t('global.month') },
    { key: 'days-time-slots', label: t('global.days_time_slots') },
    { key: 'action', label: '', align: 'end', className: 'w-[100px]' },
  ]);

  return { headers };
};
