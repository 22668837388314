<script lang="ts" setup>
import { useField } from 'vee-validate';

// Porops
const props = defineProps<{
  label: string;
  name: string;
  items: {
    label: string;
    key: string;
  }[];
  required?: boolean;
  asButton?: boolean;
}>();

// Data
const value = useField<string[]>(props.name);

// Methods
const isChecked = (key: string) => {
  return value.value.value?.includes(key);
};

const toggle = (key: string) => {
  value.value.value.includes(key)
    ? (value.value.value = value.value.value.filter((k: string) => k !== key))
    : (value.value.value = [...value.value.value, key]);
};
</script>
<template>
  <div>
    <label class="text-xs text-gray-600 mb-2 block">
      {{ label }}
      <span v-if="required" class="text-red-500">*</span>
    </label>
    <div class="flex flex-wrap gap-2">
      <div
        v-for="item in items"
        :key="`input-checkbox-${item.label}`"
        class="flex select-none items-center border border-gray-200 rounded-md py-1 px-2 cursor-pointer relative ring-offset-0"
        :class="
          isChecked(item.key)
            ? 'bg-primary-100 text-primary-500 border-primary-500 hover:ring-1 hover:ring-primary-100'
            : ' hover:border-gray-300 hover:ring-1 hover:ring-gray-100'
        "
        @click="toggle(item.key)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>
