<script lang="ts" setup>
import { useField } from 'vee-validate';

// Composables
const { energyTypeAsOptions } = useConstants();

const { value: energy_type } = useField('energy_type');
</script>
<template>
  <ui-card :title="$t('contract.contract_info')">
    <div class="grid grid-cols-2 gap-x-2">
      <ui-form-input-text
        type="number"
        placeholder="400"
        right-text="kVA"
        :label="$t('contract.subscribed_power')"
        required
        name="contract_power"
        :disabled="energy_type === 'Gas'"
      />
      <ui-form-input-select :items="energyTypeAsOptions" :label="$t('global.energy_types')" required name="energy_type" />
      <ui-form-input-date :label="$t('global.start_date')" type="date" required name="start" />
      <ui-form-input-date :label="$t('global.end_date')" type="date" required name="end" />
      <ui-form-input-text
        type="number"
        placeholder="20"
        right-text="gCO2/kWh"
        :label="$t('global.emission_factor')"
        required
        :name="`emission_factor`"
      />
    </div>
  </ui-card>
</template>
